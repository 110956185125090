<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            Замовлення: <b>{{ order.id }}</b> <br>
            <span class="caption">{{ order.date | formatDateTime }}</span>
          </template>

          <ButtonComeBack class="ml-3" />

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <material-card>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select 
                            v-model="data.status" 
                            label="Статус замовлення"
                            auto-select-first
                            chips
                            return-object
                            item-text="name"
                            item-value="name"
                            :readonly="!(isAdmin || isManager)"
                            :items="status" 
                            prepend-icon="mdi-format-list-bulleted-type">
                            <template #selection="{ item }">
                              <v-chip class="white--text" :color="`#${item.color}`">{{item.name}}</v-chip>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-select 
                            v-model="data.type" 
                            label="Тип замовлення" 
                            chips
                            return-object
                            item-text="name"
                            item-value="name"
                            :readonly="!(isAdmin || isManager)"
                            :items="types" 
                            prepend-icon="mdi-format-list-bulleted-type">
                          </v-select>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            color="purple"
                            label="Документ Т22"
                            :readonly="!(isAdmin || isManager)"
                            v-model="data.t22"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </material-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="7">
                  <material-card>
                    <v-col cols="12">
                      <v-autocomplete 
                        color="purple"
                        return-object
                        no-filter
                        hide-no-data
                        auto-select-first
                        no-data-text="Нічого не знайдено."
                        label="Місто"
                        item-text="name"
                        item-value="name"
                        v-model="data.city"
                        :items="cities"
                        :search-input.sync="searchCity"
                        :readonly="!(isAdmin || isManager)"
                        >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete 
                        color="purple"
                        no-filter
                        hide-no-data
                        return-object
                        auto-select-first
                        no-data-text="Нічого не знайдено."
                        label="Тип доставки"
                        item-text="name"
                        item-value="name"
                        v-model="data.delivery"
                        :items="delivery"
                        :readonly="!(isAdmin || isManager)"
                        >
                      </v-autocomplete>
                    </v-col>

                    <v-col v-if="!order.delivery_payload" cols="12">
                      <p class="red--text">Доставку в замовленні не вказано.<br> Зверніться до адміністраторів.</p>
                    </v-col>

                    <template v-else>
                      <v-col v-if="data.delivery.id === 2" cols="12">
                        <v-autocomplete 
                          color="purple"
                          no-filter
                          hide-no-data
                          return-object
                          auto-select-first
                          no-data-text="Нічого не знайдено."
                          label="На відділення Нової Пошти"
                          item-text="address"
                          item-value="address"
                          v-model="data.postNP"
                          :items="postNP"
                          :search-input.sync="searchPostNP"
                          :readonly="!(isAdmin || isManager)"
                          >
                        </v-autocomplete>
                      </v-col>
                      
                      <v-col v-if="data.delivery.id === 3" cols="12">
                        <v-autocomplete 
                          color="purple"
                          no-filter
                          hide-no-data
                          return-object
                          auto-select-first
                          no-data-text="Нічого не знайдено."
                          label="Забрати з магазину Техномережі F5"
                          item-text="address_ukr"
                          item-value="address"
                          v-model="data.postTTF5"
                          :items="postTTF5"
                          :readonly="!(isAdmin || isManager)"
                          >
                        </v-autocomplete>
                      </v-col>

                      <template v-if="data.delivery.id === 1">
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-text-field
                                color="purple"
                                label="Вулиця"
                                v-model="data.street"
                                :readonly="!(isAdmin || isManager)"
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                color="purple"
                                label="Будинок"
                                v-model="data.house"
                                :readonly="!(isAdmin || isManager)"
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                color="purple"
                                label="Квартира"
                                v-model="data.apartment"
                                :readonly="!(isAdmin || isManager)"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </template>
                  </material-card>
                </v-col>
                <v-col cols="12" md="5">
                  <material-card>
                    <v-col cols="12">
                      <v-text-field
                        color="purple"
                        label="ПІБ покупця"
                        v-model="data.full_name"
                        :readonly="!(isAdmin || isManager)"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        color="purple"
                        label="Телефон клієнта"
                        v-model="data.phone"
                        :readonly="!(isAdmin || isManager)"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        color="purple"
                        label="E-mail покупця"
                        v-model="data.email"
                        :readonly="!(isAdmin || isManager)"
                      />
                    </v-col>
                  </material-card>
                </v-col>
              </v-row>

              <v-row v-if="order.products && order.products.length">
                <v-col cols="12">
                  <material-card>
                    <template #title>
                      <h3 class="text-h4 mt-6">Товари замовлення</h3>
                    </template>

                    <v-col v-if="order.products && order.products.length" cols="12">
                      <v-simple-table class="mb-6">
                        <thead>
                          <tr>
                            <th class="primary--text">
                              Код
                            </th>
                            <th class="primary--text">
                              Назва
                            </th>
                            <th class="primary--text">
                              Акція
                            </th>
                            <th class="primary--text">
                              Ціна
                            </th>
                            <th class="primary--text">
                              Кількість
                            </th>
                            <th width="50"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(product, index) in order.products" :key="`order-product-${index}`">
                            <td>{{ product.id_typhoon || '-' }}</td>
                            <td>{{ product.name || '-' }}</td>
                            <td>{{ product.id_action || '-' }}</td>
                            <td>{{ product.price | numFormat }} грн</td>
                            <td>{{ product.count || '-' }}</td>
                            <td>
                              <v-icon
                                class="mx-1"
                                color="error"
                                @click="onDialogProduct(product.id)"
                              >
                                mdi-close
                              </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </material-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="5">
                  <material-card>
                    <v-col cols="12">
                      <v-text-field
                        color="purple"
                        label="Коментар покупця"
                        v-model="data.comment"
                        readonly
                      />
                    </v-col>
                  </material-card>
                </v-col>
                
                <v-col cols="12" md="7">
                  <material-card>
                    <!-- <template #title>
                      <h3 class="text-h4">Коментарі</h3>
                    </template> -->

                    <v-col cols="12">
                      <v-text-field
                        color="purple"
                        label="Коментар"
                        v-model="comment"
                        :readonly="!(isAdmin || isManager)"
                      />
                    </v-col>
                    <v-col v-if="comment.length" cols="12" class="text-right pt-0" >
                      <v-btn
                        color="primary" min-width="150"
                        @click="sendComment"
                      >
                        Додати коментар
                      </v-btn>
                    </v-col>

                    <template>
                      <v-col cols="12" v-for="(comment, index) in order.comments" :key="`comment-${index}`">
                        <v-card class="mx-auto">
                          <v-card-text>
                            <div class="text--primary">
                              {{ comment.comments }}
                            </div>
                            <div class="d-flex">
                              <p v-if="comment.user_name" class="mt-2 mb-0 mr-4">{{ comment.user_name }}</p>
                              <p class="mt-2 mb-0">{{ comment.datetime | formatDateTime }}</p>
                            </div>
                            <div class="comment-remove" @click="onDialogComment(comment.id)">
                              <v-icon
                                class="mx-1"
                                color="error"
                              >
                                mdi-close
                              </v-icon>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                  </material-card>
                </v-col>
                
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right" >
                  <v-btn color="primary" min-width="150" @click="updateOrder">
                    Зберегти
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>

    </v-row>

    <v-dialog v-model="dialogProduct" max-width="400">
      <v-card>
        <v-card-title class="text-h5 align-center">
          Ви дійсно хочете видалити товар?
        </v-card-title>
        <v-card-actions class="flex justify-center">
          <v-btn color="primary" min-width="150" text @click="onRemoveProduct">
            Так
          </v-btn>
          <v-btn color="grey" min-width="150" text @click="closeDialog">
            Нi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogComment" max-width="400">
      <v-card>
        <v-card-title class="text-h5 align-center">
          Ви дійсно хочете видалити коментар?
        </v-card-title>
        <v-card-actions class="flex justify-center">
          <v-btn color="primary" min-width="150" text @click="onRemoveComment">
            Так
          </v-btn>
          <v-btn color="grey" min-width="150" text @click="closeDialog">
            Нi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
    HTTP,
    CHECKOUT
} from '../http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex'

  export default { 
    name: 'UserProfileView',
    data() {
      return {
        order: {},
        data: {
          status: {},
          type: {},
          t22: null,
          full_name: null,
          phone: null,
          email: null,
          comment: null,
          city: null,
          delivery: {},
          postNP: null,
          postTTF5: null,
          street: null,
          house: null,
          apartment: null,
        },
        searchCity: null,
        searchPostNP: null,
        cities: [],
        postNP: [],
        postTTF5: [],
        comment: '',
        dialogProduct: false,
        dialogComment: false,
        productId: null,
        commentId: null
      }
    },
    computed: {
      ...mapGetters(['isAdmin', 'isManager', 'snackbar', 'status', 'types', 'delivery', 'orders'])
    },
    methods: {
      ...mapMutations(['setSnackbar']),
      ...mapActions(['getStatus']),
      getOrder() {
        HTTP.post('/get-order', {
          'id': this.$route.params.id 
        })
          .then(({ data }) => {
            
            this.order = data.content || {};
            this.setData(); 
          })
      },
      updateOrder() {
        let delivery_payload;

        if (this.data.delivery.id === 1) {
          delivery_payload = {
            street: this.data.street,
            house: this.data.house,
            apartment: this.data.apartment
          }
        }
        if (this.data.delivery.id === 2) {
          delivery_payload = this.data.postNP
        }
        if (this.data.delivery.id === 3) {
          delivery_payload = this.data.postTTF5
        }

        const data = {
          "id": this.order.id,
          "id_status": (this.data.status && this.data.status.id) || null,
          "status_name": this.data.status.name,
          "id_project": this.order.id_project,
          "id_service": this.order.id_service,
          "id_delivery": this.data.delivery.id,
          "id_pay": this.order.id_pay,
          "full_name": this.data.full_name,
          "phone": this.data.phone,
          "email": this.data.email,
          "id_city": this.data.city.id,
          "delivery_payload": delivery_payload,
          "street": this.data.street,
          "house": this.data.house,
          "apartment": this.data.apartment,
          "comment": this.data.comment,
          "type": (this.data.type && this.data.type.id) || null,
          "t22": this.data.t22
        }

        HTTP.post('/update-order', data)
          .then(() => {
            this.setSnackbar({ type: 'success', text: 'Успiшно збережено' });
            this.$router.back();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      setData() {
        if (this.order) {
          const initCity = { id: this.order.id_city, name: this.order.city_name, typhoon_id: this.order.city_typhoon_id };

          this.data.status = {id: this.order.id_status, name: this.order.status_name};
          this.data.type = this.types.find(el => el.id === this.order.type) || null;
          this.data.t22 = this.order.t22 || null;
          this.data.full_name = this.order.full_name || null;
          this.data.phone = this.order.phone || null;
          this.data.email = this.order['e-mail'] || null;
          this.data.comment = this.order.comment || null;
          this.data.city = initCity;
          if(initCity && initCity.name) {
            this.cities.push(initCity);
          }
          this.data.delivery = { id: this.order.id_delivery, name: this.order.delivery_naem };

          if(this.order.id_delivery === 1 && this.order.delivery_payload) {
            this.data.street = this.order.delivery_payload.street || null;
            this.data.house = this.order.delivery_payload.house || null;
            this.data.apartment = this.order.delivery_payload.apartment || null;
          }

          if(this.order.id_delivery === 2 && this.order.delivery_payload) {
            const initPostNP = { 
              id: this.order.delivery_payload.id || null,
              address: this.order.delivery_payload.address || null  
            }
            this.data.postNP = initPostNP;
            this.postNP.push(initPostNP);
          }

          if(this.order.id_delivery === 3 && this.order.delivery_payload) {
            const initPostTTF5 = { 
              id: this.order.delivery_payload.id || null, 
              address_ukr: this.order.delivery_payload.address || null,
              contrator: this.order.delivery_payload.contrator || null
            }
            this.data.postTTF5 = initPostTTF5;
          }
        }
      },
      onCities(val) {
        if (val) {
          CHECKOUT.post('/cities', {
            name: val,
            lang: 'ua'
          },
          {
            headers: { 
              'server-name': 'f5.ua' 
            }
          })
            .then(({ data }) => {
              this.cities = data.content.cities;
            })
            .catch((error) => {
              this.setSnackbar({ type: 'error', text: error.message });
            })
          }
      },
      onPostNP(val) {
        if (val) {
          CHECKOUT.post('/delivery-np', {
            city: this.data.city.typhoon_id || null,
            name: val
          },
          {
            headers: { 
              'server-name': 'f5.ua' 
            }
          })
            .then(({ data }) => {
              this.postNP = data.content.np_office;
            })
            .catch((error) => {
              this.setSnackbar({ type: 'error', text: error.message });
            })
          }
      },
      onPostTTF5() {
        CHECKOUT.post('/delivery-ttf5', {
          city: this.data.city.id || null
        },
        {
          headers: { 
            'server-name': 'f5.ua' 
          }
        })
          .then(({ data }) => {
            this.postTTF5 = data.content.shops_f5;
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      sendComment() {
        HTTP.post('/create-comment', {
          'order_id': this.order.id,
          'comments': this.comment,
          "datetime": "2020-10-10 20:20:20"
        })
          .then(() => {
            this.comment = '';
            this.getOrder();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      onRemoveProduct(id) {
        this.dialogProduct = false
        HTTP.post('/delete-order-goods', {
          'id': this.productId
        })
          .then(() => {
            this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
            this.getOrder();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      onRemoveComment() {
        this.dialogComment = false
        HTTP.post('/delete-comment', {
          'id': this.commentId
        })
          .then(() => {
            this.comment = '';
            this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
            this.getOrder();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      onDialogProduct(id) {
        this.dialogProduct = true
        this.productId = id
      },
      onDialogComment(id) {
        this.dialogComment = true
        this.commentId = id
      },
      closeDialog() {
        this.dialogProduct = false 
        this.dialogComment = false 
        this.productId = null
        this.commentId = null
      }
    },
    watch: {
      searchCity(val) {
        if (val && val !== this.order.city_name) {
          this.onCities(val)
          this.data.postNP = null
          this.data.postTTF5 = null
        } else {
          this.cities = []
          this.cities.push({ name: this.order.city_name })
        }
      },
      searchPostNP(val) {
        if (val) {
          this.onPostNP(val)
        } else {
          this.postNP = []
        }
      },
      'data.delivery': {
        handler({ id }) {
          if(id === 3) {
            this.onPostTTF5()
          }
        },
        immediate: false
      },
      'data.city.id': {
        handler(id) {
          if(this.data.delivery.id === 3) {
            this.onPostTTF5()
          }
        },
        immediate: false
      },
    },
    mounted() {
      this.getStatus();
      this.getOrder();
    }
  }
</script>

<style scoped>
  .comment-remove {
    position: absolute;
    top: 8px;
    right: 5px;
    cursor: pointer;
  }
</style>
